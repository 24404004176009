import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import CourseList from '../../components/Courses/CourseList'
import NewCourses from '../../components/NewCoursesList'
import Breadcrumb from '../../components/Breadcrumb'
import { Container, Grid, Hidden } from '@material-ui/core';

export default class CoursesIndexPage extends React.Component {
  render() {
    let location = this.props.location;
    let helmet = (
      <Helmet>
        <title>Våra kurser | Kvarntakt20</title>
        <meta
          name="description"
          content='Vi erbjuder kurser i allt från Foxtrot och Zumba, till mer specifika kurser som vår populära FemmeFox - för kvinnor som vill föra'
          />
      </Helmet>
    )
    //const  { data } = this.props;
    return (
      <Layout location={location}>
        {helmet || ''}
        <div className="main-title d-flex d-md-none">
          <h2>Våra kurser</h2>
        </div>
        <Breadcrumb path={location.pathname} title={'Våra kurser'}/>
        <section className="section mt-15">
          <Container>
            <div className="wrapper">
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <div className="main-content col col-lg-8">
                    <CourseList/>
                    <Hidden mdUp>
                      <div className="d-block d-lg-none">
                        <NewCourses/>
                      </div>
                    </Hidden>
                  </div>
                </Grid>
                <Hidden mdDown>
                  <Grid item xs={12} md={4}>
                    <div className="side-content d-none d-lg-block col-lg-4">
                      <NewCourses/>
                    </div>
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}